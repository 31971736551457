import { Switch } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
// Helpers
import RouteWithLayout from '../helpers/RouteWithLayout';

// Wrappers
import AppDefault from '../wrappers/AppDefault';
import AppWithSidebar from '../wrappers/AppWithSidebar';

import { FirebaseReducer } from 'react-redux-firebase';
import { MainRouterProps } from '../typings';
import { dotReference } from '../helpers/utils';
import QuotingQueue from '../components/Assessments/Quoting/Queue';
import OrderQueue from '../components/Assessments/Orders/Queue';
import DashboardPage from '../components/dashboard/DashboardPage';
import LoginPage from '../components/auth/LoginPage';

const MainRouter: React.FC<MainRouterProps> = (props: MainRouterProps) => {

  const quotingLabel = (assessment: any, label: string): string => {
    const value = !_.isEmpty(assessment) ? dotReference(assessment, label) : '';
    return value && _.isObjectLike(value) && _.isFunction(value.toDate)
      ? moment(value.toDate()).format('DD MMM YYYY HH:mmA')
      : value;
  };
  return (
    <Switch>
      <RouteWithLayout layout={AppDefault} path="/login" component={LoginPage} />
      {/* Quoting */}
      <RouteWithLayout
        layout={AppWithSidebar}
        path="/tasks/quoting/:assessmentId"
        component={() => (
          <QuotingQueue
            path="/tasks/quoting/"
            header="Approve"
            label={(assessment: any) => quotingLabel(assessment, 'assessmentId')}
            showDetails
          />
        )}
        requiresAuth
      />
      <RouteWithLayout
        layout={AppWithSidebar}
        path="/tasks/quoting"
        component={() => (
          <QuotingQueue
            path="/tasks/quoting/"
            label={(assessment: any) => quotingLabel(assessment, 'assessmentId')}
            header="Approve"
          />
        )}
        requiresAuth
      />
      {/* Ordering */}
      <RouteWithLayout
        layout={AppWithSidebar}
        path="/tasks/order/:assessmentId"
        component={() => (
          <OrderQueue
            path="/tasks/quoting/"
            header="Approve"
            label={(assessment: any) => quotingLabel(assessment, 'assessmentId')}
            showDetails
          />
        )}
        requiresAuth
      />
      <RouteWithLayout
        layout={AppWithSidebar}
        path="/tasks/order"
        component={() => (
          <OrderQueue
            path="/tasks/quoting/"
            label={(assessment: any) => quotingLabel(assessment, 'assessmentId')}
            header="Approve"
          />
        )}
        requiresAuth
      />

      <RouteWithLayout layout={AppWithSidebar} path="/" component={DashboardPage} requiresAuth />
    </Switch>
  );
};

const mapStateToProps = (state: { firebase: FirebaseReducer.Reducer }) => {
  return {
    profile: state.firebase.profile,
  };
};

const enhance = compose<MainRouterProps, unknown>(connect(mapStateToProps));

export default enhance(MainRouter);
