import { IPart } from '../typings';
import { IQuotePart, IQuoteReducer } from '../components/Assessments/typings/Assessments';
import {
  QUOTE_PARTS_INIT,
  QUOTE_PARTS_UPDATE,
  SUBMIT_QUOTE,
  SUBMIT_QUOTE_FAIL,
  SUBMIT_QUOTE_IN_PROGRESS,
} from '../actions/ActionTypes';
import { actionTypes } from 'redux-firestore';
import _ from 'lodash';

const INIT: IQuoteReducer = {
  loading: false,
  error: null,
  assessment: null,
  ready: false,
  parts: {},
  initialParts: {},
};

const checkPart = (part: IQuotePart): boolean => {
  if (!part || _.isEmpty(part)) return false;
  const { priceRetail, partStandard, available, supplierPartNo } = part;
  const hasRequiredFields = !!priceRetail && !!partStandard && !!available;
  if (!hasRequiredFields) {
    return (
      !available &&
      _.isEmpty(supplierPartNo) &&
      _.isEmpty(partStandard) &&
      (priceRetail === 0 || _.isNil(priceRetail))
    );
  }

  return hasRequiredFields;
};

const checkReady = (parts: { [key: string]: IQuotePart }): boolean => {
  if (_.isEmpty(parts)) return false;
  const changedParts = _.filter(parts, (p) => p.changed);
  return !_.isEmpty(changedParts) && _.every(changedParts, (p) => p.valid);
};

export default (state = INIT, action: any): IQuoteReducer => {
  switch (action.type) {
    case actionTypes.CLEAR_DATA:
      return { ...INIT };
    case QUOTE_PARTS_INIT:
      if (!action.payload.parts) {
        return { ...INIT };
      }
      const first = _.first(_.keys(action.payload.parts));
      const firstPart = action.payload.parts[first as string];
      const parts: { [key: string]: IQuotePart } = {};
      _.each(action.payload.parts, (part: IPart, id: string) => {
        const matchingSupplier =
          part.suppliers && part.suppliers[action.payload.supplierId]
            ? part.suppliers[action.payload.supplierId]
            : null;
        const newPart: IQuotePart = {
          id,
          changed: false,
          valid: false,
          oePartNo: part.part.PartNumber,
          description: part.part.Description,
          priceRetail:
            matchingSupplier && matchingSupplier.priceQuoted ? matchingSupplier.priceQuoted : 0,
          supplierPartNo:
            matchingSupplier && matchingSupplier.supplierPartNo
              ? matchingSupplier.supplierPartNo
              : '',
          available:
            matchingSupplier && matchingSupplier.available ? matchingSupplier.available : false,
          partStandard:
            matchingSupplier && matchingSupplier.partStandard ? matchingSupplier.partStandard : '',
          status: matchingSupplier ? matchingSupplier.status : null,
          statusDates: matchingSupplier ? matchingSupplier.statusDates : null,
          timers: part.timers,
        };
        parts[id] = newPart;
      });
      return {
        ...state,
        assessment: firstPart.assessment,
        parts: parts,
        initialParts: parts,
      };
    case QUOTE_PARTS_UPDATE:
      const newPartFields = {
        ...state.parts[action.payload.id],
        [action.payload.key]: action.payload.value,
      };
      const newPart = {
        ...newPartFields,
        // We only want to check editable fields for changes
        changed: !_.isEqual(
          _.omit(newPartFields, ['changed', 'valid', 'status', 'statusDates', 'timers']),
          _.omit(state.initialParts[newPartFields.id], [
            'changed',
            'valid',
            'status',
            'statusDates',
            'timers',
          ]),
        ),
      };
      const newParts = {
        ...state.parts,
        [action.payload.id]: {
          ...newPart,
          valid: checkPart(newPart),
        },
      };
      return {
        ...state,
        parts: newParts,
        ready: checkReady(newParts),
      };
    case SUBMIT_QUOTE_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_QUOTE:
      return {
        ...state,
        initialParts: { ...state.parts },
        loading: false,
      };
    case SUBMIT_QUOTE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};
