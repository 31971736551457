import { ActionCreator, Dispatch } from 'redux';
import { DECORATE_TOKEN } from './ActionTypes';
import { functions } from '../config/firebase';

export const Authenticate: ActionCreator<any> = async () => {
  return async (dispatch: Dispatch) => {
    const authenticate = functions.httpsCallable('DecorateToken');
    const result = await authenticate();
    dispatch({ type: DECORATE_TOKEN });
    return result;
  };
};
