import fb from 'firebase/app';

import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import { FIREBASE_CONFIG, IS_LOCAL } from '..';

fb.initializeApp(FIREBASE_CONFIG);

// Init firestore
fb.firestore();
fb.analytics();
fb.performance();

if (IS_LOCAL) {
  fb.functions().useFunctionsEmulator('http://localhost:5003');
}

export const firebase = fb;
export const auth = firebase.auth();
export const functions = fb.functions();
