import {
  Button,
  Col,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { ConfirmParts, InitPartConfirmation, UpdatePartConfirmation } from '../../../actions/WIP';
import { IOrderPart, IPartConfirmationReducer } from '../../Assessments/typings/Assessments';
import { InjectedProps, ModalConfig, connectModal } from 'redux-modal';
import { PartConfirmModalProps } from '../typings';
import { connect } from 'react-redux';
import { loader } from 'react-icons-kit/feather';
import { toast } from 'react-toastify';
import { useChecklist } from 'react-checklist';
import Dropzone from 'react-dropzone';
import Icon from 'react-icons-kit';
import React, { Component, useEffect, useState } from 'react';
import _ from 'lodash';
import numeral from 'numeral';

const DynPartConfirmationModal: React.FC<InjectedProps & PartConfirmModalProps> = (
  props: PartConfirmModalProps,
) => {
  const [supplierParts, setSupplierParts] = useState<IOrderPart[]>([]);
  const { title, show, handleHide, assessment } = props;
  const { handleCheck, isCheckedAll, checkedItems } = useChecklist(supplierParts, {
    key: 'id',
    keyType: 'string',
  });

  useEffect(() => {
    const payload: IOrderPart[] = [];
    _.each(assessment.parts, (part) => {
      payload.push(part);
    });
    setSupplierParts(payload);
    props.InitPartConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const partArray = Array.from(checkedItems);
    props.UpdatePartConfirmation('parts', partArray);
    // only want to update when checked items, change, not props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedItems]);

  const updateFile = (file: File, type: string) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const name = file.name;
      const lastDot = name.lastIndexOf('.');
      const ext = name.substring(lastDot + 1);
      props.UpdatePartConfirmation('file', reader.result, type, name, ext);
    };
    reader.onerror = (error) => {
      const message =
        error instanceof Error
          ? error.message
          : _.isString(error)
          ? error
          : 'An unknown error has occurred';
      toast.error(message);
    };
  };

  const renderDropZone = (type: 'invoice' | 'proofOfDelivery', label: string) => {
    const {
      partConfirmation: { files },
    }: { partConfirmation: IPartConfirmationReducer } = props;
    if (files[type] && files[type].filename) {
      return (
        <div className="filename-preview">
          <div>{files[type].filename}</div>
          <div>
            <Button
              className="file-x-button"
              onClick={() => props.UpdatePartConfirmation('file', null, type, null, null)}>
              X
            </Button>
          </div>
        </div>
      );
    }
    return (
      <Dropzone onDrop={(acceptedFiles) => updateFile(acceptedFiles[0], type)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className="file-dropzone">
              <input {...getInputProps()} />
              <p>Drag and drop your {label} here, or click to select files</p>
            </div>
          </section>
        )}
      </Dropzone>
    );
  };

  const renderConfirmationList = () => {
    return (
      <div>
        <ListGroup>
          <ListGroupItem>
            <Label check>
              <Input type="checkbox" checked={isCheckedAll} onChange={handleCheck} />
              <span />
            </Label>
            <span>
              {' '}
              <strong>Select All</strong>
            </span>
          </ListGroupItem>

          {_.map(supplierParts, (v, i) => (
            <ListGroupItem key={i}>
              <Label check>
                <Input
                  type="checkbox"
                  data-key={v.id}
                  checked={checkedItems.has(v.id)}
                  onChange={handleCheck}
                />
                <span />
              </Label>
              <span>
                {' '}
                {v.description} -{' '}
                {`R ${numeral(v.price ? v.price : 0).format('0,0.00')}`}
              </span>
            </ListGroupItem>
          ))}
        </ListGroup>
        <Row>
          <Col>{_.size(checkedItems) > 0 ? renderDropZone('invoice', 'invoice') : false}</Col>
          <Col>
            {_.size(checkedItems) > 0
              ? renderDropZone('proofOfDelivery', 'proof of delivery')
              : false}
          </Col>
        </Row>
      </div>
    );
  };

  const confirmDelivery = async () => {
    await props.ConfirmParts(props.partConfirmation);
    props.onClose(props.partConfirmation.parts);
    props.UpdatePartConfirmation('file', null, 'invoice', null, null);
    props.UpdatePartConfirmation('file', null, 'proofOfDelivery', null, null);
    handleHide();
  };

  const showLoader = () => {
    const loading = props.partConfirmation.loading;
    if (loading) {
      return <Icon icon={loader} className="spin" />;
    }
    return false;
  };

  if (!assessment.parts) {
    return <div></div>;
  }
  return (
    <Modal isOpen={show} className="part-confirmation-modal v2-modal">
      <ModalHeader>{title}</ModalHeader>
      <ModalBody className="modal-body-confirm-parts">{renderConfirmationList()}</ModalBody>
      <ModalFooter className="mt-4">
        <Button color="secondary" onClick={handleHide} disabled={props.partConfirmation.loading}>
          Cancel
        </Button>
        <Button
          color="primary"
          className="mr-0"
          onClick={() => confirmDelivery()}
          disabled={!props.partConfirmation.ready}>
          {showLoader()} Confirm Parts
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({ partConfirmation }: { partConfirmation: IPartConfirmationReducer }) => {
  return { partConfirmation };
};

// tslint:disable-next-line: max-classes-per-file
export default class DynamicModal extends Component<ModalConfig> {
  render() {
    const { name } = this.props;
    const WrappedMyModal = connectModal({ name })(
      connect(mapStateToProps, { UpdatePartConfirmation, InitPartConfirmation, ConfirmParts })(
        DynPartConfirmationModal,
      ),
    );
    return <WrappedMyModal {...this.props} />;
  }
}
