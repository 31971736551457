import Loader from 'react-loaders';
import React from 'react';

import '../../styles/LoadingPage.scss';

const LoadingPage: React.FC = () => (
  <div className="fill flex center loading-page">
    <div className="loading-container">
      <Loader type="ball-pulse-sync" active />
    </div>
  </div>
);
export default LoadingPage;
