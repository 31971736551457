import { FirebaseReducer, getFirebase } from 'react-redux-firebase';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';

import LoadingPage from '../loading/LoadingPage';

import '../../styles/DashboardPage.scss';
import history from '../../helpers/history';

const DashboardPage: React.FC<
  RouteComponentProps & FirebaseReducer.Reducer & DashboardPageProps
> = (props: RouteComponentProps & FirebaseReducer.Reducer & DashboardPageProps) => {
  useEffect(() => {
    history.push('/tasks/quoting');
  }, [])
  const { loading } = props;
  if (loading) {
    return <LoadingPage />;
  }
  return (
      <div className="dashboard-page-container">
        <div className="dashboard-tile-container">Loading...</div>
      </div>
  )
}

const mapStateToProps = ({
  firebase: { auth, profile },
}: {
  firebase: FirebaseReducer.Reducer;
}) => ({
  auth,
  profile,
  firebase: getFirebase(),
});

export default compose(connect(mapStateToProps))(DashboardPage);
