import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FirebaseReducer } from 'react-redux-firebase';
import { GetImage, ResetImages } from '../../../actions/Images';
import { IImageReducer } from '../../../typings';
import { ImagesModalState } from '../typings';
import { Line } from 'rc-progress';
import { ModalConfig, connectModal } from 'redux-modal';
import { connect } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import Loader from 'react-loaders';
import React, { Component } from 'react';
import _ from 'lodash';
import async from 'async';

import './styles.scss';
import 'react-image-gallery/styles/css/image-gallery.css';
import { IAssessment } from '../../Assessments/typings/Assessments';

const mapStateToProps = ({
  firebase: { profile },
  images,
  assessment: { selectedAssessment },
}: {
  firebase: FirebaseReducer.Reducer;
  images: IImageReducer;
  assessment: any;
}) => ({
  profile,
  image: images.image,
  allImages: images.allImages,
  assessment: selectedAssessment,
});

let globalIsMounted = false;

@(connect(mapStateToProps, { GetImage, ResetImages }) as any)
class DynImagesModal extends Component<any, any & ImagesModalState> {
  private static INITIAL_STATE: ImagesModalState = {
    loading: true,
    index: 0,
    imagesCount: 0,
    imagesLoadingError: false,
  };

  constructor(props: any) {
    super(props);
    this.state = { ...DynImagesModal.INITIAL_STATE };
    globalIsMounted = true;
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onEsc.bind(this), false);
    const { data, id, profile, GetImage, assessment } = this.props;

    this.props.ResetImages({ assessmentId: id });

    this.setState({
      imagesCount: data.length,
    });

    async.forEachOfLimit(
      data,
      2,
      (image, index, callback) => {
        GetImage(
          {
            id: image,
            insurer: profile.insurers,
            assessmentId: id,
            repairerId: (assessment as IAssessment).repairer.id,
          },
          () => {
            globalIsMounted ? callback() : callback(new Error('unmount'));
          },
        ).catch((err: any) => {
          this.setState({
            imagesLoadingError: true,
          });
          callback(err);
        });
      },
      (err) => {
        if (err && err.message !== 'unmount') {
          console.error(err);
        }

        this.setState({
          ...this.state,
          loading: false,
        });
      },
    );
  }

  componentWillUnmount() {
    this.props.ResetImages({ assessmentId: '' });
    globalIsMounted = false;
    document.removeEventListener('keydown', this.onEsc.bind(this), false);
  }

  onEsc(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.props.handleHide();
    }
  }

  onSlide = (ev: any) => {
    this.setState({
      index: ev,
    });
  };

  renderLoading() {
    const { loading } = this.state;
    const { data, allImages } = this.props;
    if (!loading) {
      return false;
    }
    const percentageDone = (_.size(allImages) / _.size(data)) * 100;
    return (
      <div className="image-loading-indicator">
        <Line
          percent={percentageDone}
          strokeLinecap="square"
          strokeWidth={4}
          trailWidth={4}
          trailColor="#eee"
          strokeColor="#444"
          className="image-progress-bar"
        />
      </div>
    );
  }

  render() {
    const { show, allImages, data, confirmButtonText, handleHide } = this.props;
    const { loading } = this.state;
    return (
      <Modal isOpen={show} className="images-modal v2-modal">
        <ModalBody>
          {loading && (_.isEmpty(allImages) || _.isEmpty(data)) ? (
            <Loader type="ball-pulse-sync" active />
          ) : (
            <ImageGallery
              items={allImages}
              showPlayButton={false}
              startIndex={this.state.index}
              onSlide={this.onSlide}
            />
          )}
        </ModalBody>
        <ModalFooter className="flex images-modal-footer">
          {this.renderLoading()}
          <div className="images-count">
            {loading && (_.isEmpty(allImages) || _.isEmpty(data)) ? (
              '0 / ' + this.state.imagesCount
            ) : (
              <span className={this.state.imagesLoadingError ? 'img-loading-err-count' : ''}>
                {allImages.length} / {this.state.imagesCount}
              </span>
            )}
          </div>
          <div className="loading-error">
            {this.state.imagesLoadingError && (
              <span>
                An unexpected error ocurred while loading images. Try reopening modal window.
              </span>
            )}
          </div>
          <Button color="primary" onClick={handleHide}>
            {confirmButtonText || 'Confirm'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

// tslint:disable-next-line: max-classes-per-file
export default class DynamicModal extends Component<ModalConfig> {
  render() {
    const { name } = this.props;
    const WrappedMyModal = connectModal({ name })(DynImagesModal);
    return <WrappedMyModal {...this.props} />;
  }
}
