import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FetchVehicleInfo } from '../../../actions/Assessment';
import { ImagesModalState } from '../typings';
import { ModalConfig, connectModal } from 'redux-modal';
import Loader from 'react-loaders';
import Masonry from 'react-masonry-css';
import React, { Component } from 'react';
import _ from 'lodash';

class DynVinModal extends Component<any, any & ImagesModalState> {
  isUnmounted = false;

  constructor(props: any) {
    super(props);
    this.renderVinGroup = this.renderVinGroup.bind(this);
    this.renderVinItem = this.renderVinItem.bind(this);
    this.state = { vehicle: <Loader type="ball-pulse-sync" active={true} /> };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onEsc.bind(this), false);

    const { selectedAssessment, profile } = this.props;
    const getVehichleInfo = async () => {
      const response = await FetchVehicleInfo(
        selectedAssessment.id,
        selectedAssessment.assessment.Vehicle.Vin,
        profile.insurers,
      );

      if (this.isUnmounted) {
        return;
      }

      if (response.vehicle.message) {
        this.setState({
          vehicle: this.renderNoVinData(response.vehicle.message),
        });
      } else {
        const info: { [x: string]: any } = _.groupBy(response.vehicle, 'SpecCategory');
        const sortingArray = [
          'Vehicle definition',
          'Dimensions & Weights',
          'Convenience',
          'General',
          'Safety',
          'Steering',
          'Engine',
          'Styling',
          'Warranty & Service Plans',
          'Transmission',
          'Wheels',
        ];
        const infoSorted: { [x: string]: any } = {};

        sortingArray.forEach((key: string) => {
          infoSorted[key] = info[key];
        });

        this.setState({
          vehicle: (
            <Masonry
              breakpointCols={3}
              className="masonry-grid"
              columnClassName="masonry-grid_column">
              {_.map(infoSorted, this.renderVinGroup)}
            </Masonry>
          ),
        });
      }
    };

    getVehichleInfo();
  }

  componentWillUnmount() {
    this.isUnmounted = true;
    document.removeEventListener('keydown', this.onEsc.bind(this), false);
  }

  onEsc(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.props.handleHide();
    }
  }

  renderNoVinData(msg: string) {
    return <div className="no-vin-info">{msg}</div>;
  }

  renderVinItem(item: any, key: string) {
    const ignoreList = ['ServiceStatus', 'CarId', 'vinNumber'];
    if (
      _.includes(ignoreList, item.Description) ||
      !item.Description ||
      _.isEmpty(item.Description)
    ) {
      return false;
    }
    return (
      <Col sm={12} key={`${key}-${item.Description}`} className="vinInfoItem">
        <Row>
          <Col sm={7}>
            <strong>{_.startCase(item.Description)}:</strong>
          </Col>
          <Col sm={5}>{_.isString(item.Value) ? item.Value : '-'}</Col>
        </Row>
      </Col>
    );
  }

  renderVinGroup(group: any, key: string) {
    if (!key || key.toLowerCase() === 'undefined') {
      return false;
    }
    return (
      <div key={key} className="box-group">
        <div className="box-container">
          <h4>{_.startCase(key)}</h4>
          <Row>{_.map(group, this.renderVinItem)}</Row>
        </div>
      </div>
    );
  }

  render() {
    const { title, show, confirmButtonText, handleHide } = this.props;
    return (
      <Modal isOpen={show} className="vin-modal v2-modal">
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{this.state.vehicle}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleHide}>
            {confirmButtonText || 'Confirm'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

// tslint:disable-next-line: max-classes-per-file
export default class DynamicModal extends Component<ModalConfig> {
  render() {
    const { name } = this.props;
    const WrappedMyModal = connectModal({ name })(DynVinModal);
    return <WrappedMyModal {...this.props} />;
  }
}
