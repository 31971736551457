import Loader from 'react-loaders';
import React from 'react';

import './LoaderSmall.scss';

export const LoaderSmall = (props?: { white?: boolean; additionalClass?: string }) => {
  return (
    <div
      className={`load-more-balls small ${props?.white ? 'white-balls' : ''} ${
        props?.additionalClass
      }`}>
      <Loader type="ball-pulse-sync" active={true} />
    </div>
  );
};
