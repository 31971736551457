import { ISearchReducer } from '../typings';
import {
  SEARCH_ASSESSMENTS_FAIL,
  SEARCH_ASSESSMENTS_IN_PROGRESS,
  SEARCH_ASSESSMENTS_SUCCESS,
} from '../actions/ActionTypes';
import { actionTypes } from 'redux-firestore';

const INIT: ISearchReducer = {
  searching: false,
  error: null,
  results: [],
};

export default (state = INIT, action: any): ISearchReducer => {
  switch (action.type) {
    case actionTypes.CLEAR_DATA:
      return { ...INIT };
    case SEARCH_ASSESSMENTS_IN_PROGRESS:
      return {
        ...state,
        searching: true,
        results: [],
      };
    case SEARCH_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        searching: false,
        results: action.payload,
      };
    case SEARCH_ASSESSMENTS_FAIL:
      return {
        ...state,
        searching: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};
