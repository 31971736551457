import { RouteComponentProps } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import React, { Component } from 'react';

import Sidebar from '../components/sidebar/Sidebar';

import '../styles/Suppliers.scss';
import '../styles/Wrapper.scss';
import 'react-toastify/dist/ReactToastify.css';

import ImageModal from '../components/modals/images';
import PartConfirmationModal from '../components/modals/part-confirmation';
import VinModal from '../components/modals/vin';

export default class App extends Component<RouteComponentProps> {
  render = (): React.ReactNode => {
    return (
      
      <div className="main">
        <ToastContainer />
        <ImageModal name="ImageModal" />
        <VinModal name="VinModal" />
        <PartConfirmationModal name="PartConfirmationModal" />
        <div className="admin-container">
          <div className={`admin-sidebar`}>
              <Sidebar />
          </div>
          <div className="main-has-sidebar">
                {this.props.children}
          </div>
        </div>
      </div>
    );
  };
}
