import { IQuoteSingle } from '../components/Assessments/Quoting/typings';
import { SELECT_ASSESSMENT } from '../actions/ActionTypes';
import { actionTypes } from 'redux-firestore';

const INIT: {
  selectedAssessment: IQuoteSingle | null;
} = {
  selectedAssessment: null,
};

export default (state = INIT, action: any) => {
  switch (action.type) {
    case actionTypes.CLEAR_DATA:
      return { ...INIT };
    case SELECT_ASSESSMENT:
      if (action.payload === null) {
        return { ...INIT };
      }
      if (!action.payload) {
        return { ...state };
      }
      const quote = action.payload as IQuoteSingle;
      return {
        ...state,
        selectedAssessment: { ...quote },
      };
    default:
      return { ...state };
  }
};
