import { ADD_IMAGE, RESET_IMAGES } from './ActionTypes';
import { ActionCreator, Dispatch } from 'redux';
import { functions } from '../config/firebase';

export const GetImage: ActionCreator<any> = async (
  payload: { id: string; insurer: string; assessmentId: string; repairerId: string },
  callback: () => void,
) => {
  return async (dispatch: Dispatch) => {
    const getImage = functions.httpsCallable('GetImage');
    const { id, insurer, assessmentId, repairerId } = payload;
    const image = await getImage({ id, insurer, repairerId });
    dispatch({
      type: ADD_IMAGE,
      payload: {
        image: image.data,
        id: assessmentId,
      },
    });
    callback();
  };
};

export const ResetImages: ActionCreator<any> = async (payload: { assessmentId: string }) => {
  return async (dispatch: Dispatch) => {
    const { assessmentId } = payload;
    dispatch({
      type: RESET_IMAGES,
      payload: {
        id: assessmentId,
      },
    });
  };
};
