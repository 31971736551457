import { ActionCreator, Dispatch } from 'redux';
import {
  CONFIRM_PARTS_FAIL,
  CONFIRM_PARTS_INIT,
  CONFIRM_PARTS_IN_PROGRESS,
  CONFIRM_PARTS_SUCCESS,
  CONFIRM_PARTS_UPDATE_FILE,
  CONFIRM_PARTS_UPDATE_PART,
} from './ActionTypes';
import { functions } from '../config/firebase';

export const InitPartConfirmation: ActionCreator<any> = async () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CONFIRM_PARTS_INIT,
    });
  };
};

export const UpdatePartConfirmation: ActionCreator<any> = async (
  type: 'file' | 'parts',
  payload: string | string[],
  fileType?: 'invoice' | 'proofOfDelivery' | null,
  filename?: string,
  extension?: string,
) => {
  return async (dispatch: Dispatch) => {
    let dispatchType;
    let dispatchPayload;
    switch (type) {
      case 'file':
        dispatchType = CONFIRM_PARTS_UPDATE_FILE;
        dispatchPayload = {
          fileType,
          file: payload,
          filename: filename,
          extension: extension,
        };
        break;
      case 'parts':
        dispatchType = CONFIRM_PARTS_UPDATE_PART;
        dispatchPayload = payload;
        break;
      default:
        throw new Error('`type` must be a file or an array of part ids');
    }
    dispatch({
      type: dispatchType,
      payload: dispatchPayload,
    });
  };
};

export const ConfirmParts: ActionCreator<any> = async (
  assessment: string,
  insurer: string,
  payload: {
    parts: string[];
    files: {
      invoice: {
        file: string;
        extension: string;
      };
      proofOfDelivery: {
        file: string;
        extension: string;
      };
    };
  },
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CONFIRM_PARTS_IN_PROGRESS,
    });
    try {
      const confirmPartDelivery = functions.httpsCallable('ConfirmPartDelivery');
      const { parts, files } = payload;
      await confirmPartDelivery({ parts, files, assessment, insurer });
      dispatch({
        type: CONFIRM_PARTS_SUCCESS,
      });
      return true;
    } catch (ex) {
      dispatch({
        type: CONFIRM_PARTS_FAIL,
      });
    }
  };
};
