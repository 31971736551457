import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { reducer as modal } from 'redux-modal';
import AssessmentReducer from './Assessment';
import ImageReducer from './Images';
import PaginationReducer from './Pagination';
import PartConfirmationReducer from './PartConfirmation';
import QuoteReducer from './Quote';
import SearchReducer from './Search';

const rootReducer = (history: any) =>
  combineReducers({
    modal,
    router: connectRouter(history),
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    pagination: PaginationReducer,
    assessment: AssessmentReducer,
    images: ImageReducer,
    partConfirmation: PartConfirmationReducer,
    quote: QuoteReducer,
    search: SearchReducer,
  });

export default rootReducer;
