import { ActionCreator, Dispatch } from 'redux';
import { IAssessment } from '../components/Assessments/typings/Assessments';
import { SELECT_ASSESSMENT } from './ActionTypes';
import { functions } from '../config/firebase';

export const SelectAssessment: ActionCreator<any> = (payload: IAssessment) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SELECT_ASSESSMENT,
      payload,
    });
  };
};

export const UnSelectAssessment: ActionCreator<any> = (payload: IAssessment) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SELECT_ASSESSMENT,
      payload: null,
    });
  };
};

export const FetchVehicleInfo = async (id: string, vin: string, insurer: string) => {
  try {
    const lookupVinNumber = functions.httpsCallable('LookupVinNumber');
    const vehicleInfo = await lookupVinNumber({ assessment: id, vin, insurer });
    return vehicleInfo.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
