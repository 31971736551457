import React from 'react';
import { Icon } from 'react-icons-kit';
import { x } from 'react-icons-kit/feather';
import { Button } from 'reactstrap';
import { OrderSidePanelProps } from '../typings';
import Details from './Details';

const SidePanel:React.FC<OrderSidePanelProps> = (props: OrderSidePanelProps) => {
  const onClose = () => {
    props.onClose();
  }

  const renderDetails = () => {
    const { assessment } = props;
    if (!assessment) {
      return false;
    }
    return (
      <div className="part-details">
        <h1 className="text-right">
          {assessment.assessment?.AssessmentNumber}
        </h1>
        <Details assessment={assessment} />
      </div>
    )
  }
    
  return (
    <div className={`floating-controls supplier ${props.assessment ? 'open' : ''}`}>
      <Button color="link" className="floating-close-btn" onClick={onClose}>
        <Icon icon={x} size={24} />
      </Button>
      {renderDetails()}
    </div>
  )
}


export default SidePanel;