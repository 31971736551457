import { ActionCreator, Dispatch } from 'redux';

export const LoadMore: ActionCreator<any> = (skip: number) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'LOAD_MORE',
      value: skip,
    });
  };
};

export const ResetPaging: ActionCreator<any> = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'RESET_PAGINATION',
    });
  };
};
