import {
  CONFIRM_PARTS_FAIL,
  CONFIRM_PARTS_INIT,
  CONFIRM_PARTS_IN_PROGRESS,
  CONFIRM_PARTS_SUCCESS,
  CONFIRM_PARTS_UPDATE_FILE,
  CONFIRM_PARTS_UPDATE_PART,
} from '../actions/ActionTypes';
import { IPartConfirmationReducer } from '../components/Assessments/typings/Assessments';
import { actionTypes } from 'redux-firestore';
import _ from 'lodash';

const INIT: IPartConfirmationReducer = {
  files: {
    invoice: {
      file: null,
      filename: null,
      extension: null,
    },
    proofOfDelivery: {
      file: null,
      filename: null,
      extension: null,
    },
  },
  parts: [],
  success: false,
  loading: false,
  ready: false,
};

export default (state = INIT, action: any) => {
  switch (action.type) {
    case actionTypes.CLEAR_DATA:
      return { ...INIT };
    case CONFIRM_PARTS_INIT:
      return {
        ...INIT,
      };
    case CONFIRM_PARTS_UPDATE_PART:
      return {
        ...state,
        parts: action.payload,
        ready:
          !_.isEmpty(action.payload) &&
          (!_.isNil(state.files.invoice.file) || !_.isNil(state.files.proofOfDelivery.file)),
      };
    case CONFIRM_PARTS_UPDATE_FILE:
      // split out the raw B64 string
      const fileArray = action.payload.file ? action.payload.file.split(',') : null;
      const file = fileArray ? fileArray.slice(1).join('') : null;
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.fileType]: {
            file: file,
            filename: action.payload.filename,
            extension: action.payload.extension,
          },
        },
        ready: !_.isEmpty(state.parts) && !_.isNil(file),
      };
    case CONFIRM_PARTS_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case CONFIRM_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case CONFIRM_PARTS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return { ...state };
  }
};
