import { IRouteWithLayoutProps } from '../typings';
import { Route } from 'react-router-dom';
import React from 'react';
import RequireAuth from '../components/auth/RequireAuth';

const RouteWithLayout: React.FC<IRouteWithLayoutProps> = (props) => {
  const { layout, component, requiresAuth, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (requiresAuth) {
          return (
            <RequireAuth>
              {React.createElement(layout, props, React.createElement(component, props))}
            </RequireAuth>
          );
        }
        return React.createElement(layout, props, React.createElement(component, props));
      }}
    />
  );
};
export default RouteWithLayout;
