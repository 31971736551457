import { FirebaseReducer, getFirebase, isEmpty, isLoaded } from 'react-redux-firebase';
import { History, Location } from 'history';
import { Redirect, Route } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import LoadingPage from '../loading/LoadingPage';
import React from 'react';

const returnToLogin = (location?: Location<History.UnknownFacade>) => {
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
const RequireAuth = ({ children, profile, firebase, ...rest }: any) => {
  const auth = useSelector((state: any) => state.firebase.auth);
  if (isLoaded(auth) && profile.sites && profile.sites.supplier === false) {
    firebase.logout();
    returnToLogin();
  }
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isLoaded(auth)) {
          return <LoadingPage />;
        }
        if (!isEmpty(auth)) {
          return children;
        }
        return returnToLogin(location);
      }}
    />
  );
};

const mapStateToProps = ({ firebase }: { firebase: FirebaseReducer.Reducer }) => {
  const { profile } = firebase;
  return {
    profile,
    firebase: getFirebase(),
  };
};

export default connect(mapStateToProps)(RequireAuth);
