import { actionTypes } from 'redux-firestore';
import _ from 'lodash';

export const dotReference = (obj: any, ref: string): any => {
  return ref.split('.').reduce((o, i) => o[i], obj);
};

export const isIsoDate = (str: string) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d.toISOString() === str;
};

export const dotNotationReference = (path: string, obj: any) =>
  path.split('.').reduce((o, i) => o[i], obj);

export const validateEmail = (email: string): boolean => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone: string): boolean => {
  // eslint-disable-next-line
  const re = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
  return re.test(String(phone).toLowerCase());
};

export const Base64 = {
  // private property
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

  encodeBinary(input: any) {
    let output = '';
    let bytebuffer;
    const encodedCharIndexes = new Array(4);
    let inx = 0;
    let paddingBytes = 0;

    while (inx < input.length) {
      // Fill byte buffer array
      bytebuffer = new Array(3);
      for (let jnx = 0; jnx < bytebuffer.length; jnx++) {
        if (inx < input.length) bytebuffer[jnx] = input.charCodeAt(inx++) & 0xff;
        // throw away high-order byte, as documented at: https://developer.mozilla.org/En/Using_XMLHttpRequest#Handling_binary_data
        else bytebuffer[jnx] = 0;
      }

      // Get each encoded character, 6 bits at a time
      // index 1: first 6 bits
      encodedCharIndexes[0] = bytebuffer[0] >> 2;
      // index 2: second 6 bits (2 least significant bits from input byte 1 + 4 most significant bits from byte 2)
      encodedCharIndexes[1] = ((bytebuffer[0] & 0x3) << 4) | (bytebuffer[1] >> 4);
      // index 3: third 6 bits (4 least significant bits from input byte 2 + 2 most significant bits from byte 3)
      encodedCharIndexes[2] = ((bytebuffer[1] & 0x0f) << 2) | (bytebuffer[2] >> 6);
      // index 3: forth 6 bits (6 least significant bits from input byte 3)
      encodedCharIndexes[3] = bytebuffer[2] & 0x3f;

      // Determine whether padding happened, and adjust accordingly
      paddingBytes = inx - (input.length - 1);
      switch (paddingBytes) {
        case 2:
          // Set last 2 characters to padding char
          encodedCharIndexes[3] = 64;
          encodedCharIndexes[2] = 64;
          break;
        case 1:
          // Set last character to padding char
          encodedCharIndexes[3] = 64;
          break;
        default:
          break; // No padding - proceed
      }
      // Now we will grab each appropriate character out of our keystring
      // based on our index array and append it to the output string
      for (let jnx = 0; jnx < encodedCharIndexes.length; jnx++)
        output += this._keyStr.charAt(encodedCharIndexes[jnx]);
    }
    return output;
  },
};

export function deepDiff(o: any, b: any): { [key: string]: any } {
  function changes(object: any, base: any) {
    return _.transform(object, (result: any, value: any, key: any) => {
      if (!_.isEqual(value, base[key])) {
        const isObject = _.isObject(value) && _.isObject(base[key]);
        const isDate = _.isDate(value) && _.isDate(base[key]);
        result[key] = isObject && !isDate ? changes(value, base[key]) : value;
        const theResult: any = result[key];
        if (_.isArray(theResult)) {
          result[key] = _.compact(_.map(theResult, (r) => r));
        }
      }
    });
  }
  return changes(o, b);
}

export const ClearFirestoreData = (preserveData = []) => (dispatch: any) => {
  dispatch({
    type: actionTypes.CLEAR_DATA,
    preserve: {
      data: preserveData,
      ordered: preserveData,
      composite: preserveData,
    },
  });
};

export const openPdf = (base64str: string) => {
  // base64 string

  // decode base64 string, remove space for IE compatibility
  const binary = atob(base64str.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }

  // create the blob object with content-type "application/pdf"
  const blob = new Blob([view], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  window.open(url);
};
