import devCredentials from './firebase/credentials.dev.json';
import prodCredentials from './firebase/credentials.prod.json';

export const DEVELOPMENT_MODE = process.env.GCLOUD_PROJECT !== prodCredentials.projectId;

export const DOMAIN = 'partsportal.co.za';

export const BASE_URL =
  process.env.LOCAL_MACHINE === 'true'
    ? `http://localhost:5003/claimhandler-dev/us-central1`
    : `https://us-central1-${process.env.GCLOUD_PROJECT}.cloudfunctions.net`;

export const IS_LOCAL = process.env.LOCAL_MACHINE === 'true';
export const FIREBASE_CONFIG = !DEVELOPMENT_MODE ? prodCredentials : devCredentials;
