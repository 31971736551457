import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import {
  ReactReduxFirebaseConfig,
  ReactReduxFirebaseProvider,
  getFirebase,
} from 'react-redux-firebase';
import { applyMiddleware, compose, createStore } from 'redux';
import { createFirestoreInstance, getFirestore } from 'redux-firestore';
import { firebase } from './config/firebase';
import ReduxPromise from 'redux-promise';
import reducers from './reducers';
import thunk from 'redux-thunk';
// Helpers
import 'react-datepicker/dist/react-datepicker.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { DEVELOPMENT_MODE } from './config';
import MainRouter from './wrappers/MainRouter';
import history from './helpers/history';

if (DEVELOPMENT_MODE) {
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  favicon.href = '/favicon_red.ico';
}

// Middlewares
const middlewares = [ReduxPromise, thunk.withExtraArgument({ getFirebase, getFirestore })];

// react-redux-firebase config
const rrfConfig: Partial<ReactReduxFirebaseConfig> = {
  userProfile: 'users', // where profiles are stored in database
  useFirestoreForProfile: true,
  enableClaims: true,
};

// Create store with reducers and initial state
const initialState = {};
const store = createStore(
  reducers(history),
  initialState,
  compose(applyMiddleware(...middlewares)),
);

const rrfProps = {
  firebase,
  createFirestoreInstance,
  config: rrfConfig,
  dispatch: store.dispatch,
};

const App = (): JSX.Element => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ConnectedRouter history={history}>
          <MainRouter />
        </ConnectedRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};
export default App;
