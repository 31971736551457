import { ADD_IMAGE, RESET_IMAGES, SELECT_ASSESSMENT } from '../actions/ActionTypes';
import { IImageReducer, IReducerAction } from '../typings';

const INIT: IImageReducer = {
  image: { original: '', thumbnail: '' },
  allImages: [],
  id: '',
};

export default (state = INIT, action: IReducerAction): IImageReducer => {
  switch (action.type) {
    case ADD_IMAGE:
      if (!action.payload) {
        return {
          ...state,
        };
      }

      const actionId = action.payload.id as string;
      const image = action.payload.image as string;

      const existingImage = state.allImages.some((o) => o.original === image);

      return actionId === state.id && !existingImage
        ? {
            ...state,
            allImages: [...state.allImages, { original: image, thumbnail: image }],
            image: { original: image, thumbnail: image },
          }
        : {
            ...state,
          };
    case RESET_IMAGES:
      return {
        ...INIT,
        id: action.payload.id,
      };
    case SELECT_ASSESSMENT:
      return { ...INIT };
    default:
      return { ...state };
  }
};
