import { Authenticate } from '../../actions/Auth';
import { Button, Form, FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import {
  FirebaseReducer,
  WithFirebaseProps,
  getFirebase,
  isEmpty,
  isLoaded,
} from 'react-redux-firebase';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { lock, user } from 'react-icons-kit/feather';
import Icon from 'react-icons-kit';
import Loader from 'react-loaders';
import React, { Component } from 'react';
import history from '../../helpers/history';

import '../../styles/LoginPage.scss';
import { LoginPageProps, LoginPageState } from './typings';
import { compose } from 'redux';

const ERROR_MESSAGE =
  'Invalid username and/or password. Please check your username and password and try again.';

class LoginPage extends Component<
  RouteComponentProps & WithFirebaseProps<any> & LoginPageProps,
  LoginPageState
> {
  private static INITIAL_STATE: LoginPageState = {
    email: '',
    password: '',
    error: null,
    loading: false,
  };

  private static propKey(propertyName: string, value: any): any {
    return { [propertyName]: value };
  }

  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(LoginPage.propKey(columnType, event.target.value));
  }

  constructor(props: RouteComponentProps & WithFirebaseProps<any> & LoginPageProps) {
    super(props);
    this.state = { ...LoginPage.INITIAL_STATE };
  }

  componentDidUpdate() {
    const { auth, profile } = this.props;

    if (isLoaded(auth) && !isEmpty(auth) && profile.isLoaded && profile.sites.supplier === true) {
      history.push('/');
    }
  }

  private checkAccess = () => {
    const { profile } = this.props;
    if (profile.isLoaded && profile.sites && !profile.sites.supplier) {
      this.setState({
        loading: false,
        error: ERROR_MESSAGE,
      });
      return false;
    }
    return true;
  };

  public onSubmit = async (event: any) => {
    event.preventDefault();
    const { email, password } = this.state;
    this.setState({
      loading: true,
      error: null,
    });
    this.props.firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        if (!this.checkAccess()) {
          return;
        }
        return this.props.Authenticate();
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: ERROR_MESSAGE,
        });
      });
  };

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';
    return (
      <div className="fill flex center login-page">
        <Form className="login-container" onSubmit={(event) => this.onSubmit(event)}>
          <div className="login-header">
            <div className="login-icon" />
            <h1>PartsPortal</h1>
            <h2>Suppliers</h2>
          </div>
          <div className="form-groups">
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Icon icon={user} size={20} />
                </InputGroupAddon>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  placeholder="Username/Email"
                  onChange={(event) => this.setStateWithEvent(event, 'email')}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Icon icon={lock} size={20} />
                </InputGroupAddon>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  placeholder="Password"
                  onChange={(event) => this.setStateWithEvent(event, 'password')}
                />
              </InputGroup>
            </FormGroup>
          </div>
          {error && <p className="text-danger text-center no-margin">{error}</p>}
          {this.state.loading && <Loader type="ball-pulse-sync" active />}
          <Button color="primary" disabled={isInvalid} type="submit" className="login-button">
            Login
          </Button>
        </Form>
      </div>
    );
  }
}

export default compose<RouteComponentProps & WithFirebaseProps<any> & LoginPageProps>(
  connect(
    ({ firebase: { auth, profile } }: { firebase: FirebaseReducer.Reducer }) => ({
      firebase: getFirebase(),
      profile,
      auth,
    }),
    { Authenticate },
  ),
)(LoginPage);
