import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect, FirestoreReducer, ReduxFirestoreQueries } from 'react-redux-firebase';
import { Table } from 'reactstrap';
import { compose } from 'redux';
import { IPartV2 } from '../../typings/Assessments';
import { QuoteDetailsProps } from '../typings';

const Details: React.FC<QuoteDetailsProps> = (props: QuoteDetailsProps) => {
 
  const renderPart = (part:IPartV2, index:number) => {
    return (
      <tr key={`part-${index}`}>
        <td className="text-left">{part.part.PartNumber}</td>
        <td className="text-left">{part.part.Description}</td>
        <td className="text-left">{part.matchedPart?.partStandard || '-'}</td>
        <td className="text-left">R {part.matchedPart?.partPrice ? part.matchedPart.partPrice.toFixed(2) : '-'}</td>
      </tr>
    );
  }

  const { assessment, parts } = props;
  if(!assessment || !parts) return <div></div>;
  
  return (
    <div className="part-information">
      <div className="order-parts">
        <Table striped className="">
          <colgroup>
          <col width="20%" />
                <col width="40%" />
                <col width="20%" />
                <col width="20%" />
          </colgroup>
          <thead>
            <tr>
              <th>Part No.</th>
              <th>Description</th>
              <th>Standard</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {_.map(parts, renderPart)}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const mapQueryToProps = (props:any) => {
  if(!props.assessment) {
    return [];
  }
  const queries:ReduxFirestoreQueries = [{
    collection: 'parts_v2',
    where: ['assessment', '==', props.assessment.id],
    storeAs: 'parts',
  }]
  return queries;
}

const mapStateToProps = ({ firestore }: { firestore: FirestoreReducer.Reducer }) => {
  return {
    parts: firestore.data.parts,
  }
}

export default compose<React.ComponentType<Partial<QuoteDetailsProps>>>(
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps)
)(Details);