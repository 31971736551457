import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { FirebaseReducer, getFirebase, WithFirebaseProps } from 'react-redux-firebase';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { ClearFirestoreData } from '../../helpers/utils';
import { SidebarProps } from './typings';
import Icon from 'react-icons-kit';
import { home, shoppingBag } from 'react-icons-kit/feather';
import '../../styles/Sidebar.scss';
import history from '../../helpers/history';

function _isActive(route:string) {
  return window.location.pathname.indexOf(route) > -1;
}

const sidebarIconSize = 24;

class Sidebar extends Component<WithFirebaseProps<any> & RouteComponentProps & SidebarProps> {
  renderLogo() {
    return (
      <div className="back-brand-container">
        <Link to="/" className="logo-sidebar">
            <span className="tk-paralucent demi-bold">// PartsPortal</span>
            <span className="tk-paralucent demi-bold sub-brand">Supplier</span>
        </Link>
      </div>
    );
  }

  logout() {
    this.props.firebase.logout();
    history.push('/login');
  };

  renderSignout() {
      return (
        <div className="sidebar-bottom">
          <Button color="link" className="signout-button" size="lg" onClick={this.logout.bind(this)}>
              <span>Sign Out</span>
          </Button>
        </div>
      );
  }

  renderRootViewList() {
    return ([
        <ListGroupItem key="quoting" disabled={false} active={_isActive('/tasks/quoting')}>
            <Link to="/tasks/quoting">
                <Icon icon={home} size={sidebarIconSize} /><p>Quoting</p>
            </Link>
        </ListGroupItem>,
        <ListGroupItem key="orders" active={_isActive('/tasks/order')}>
            <Link to="/tasks/order">
                <Icon icon={shoppingBag} size={sidebarIconSize} /><p>Orders</p>
            </Link>
        </ListGroupItem>
    ])
  }

  renderMenu() {
      return (
          <ListGroup flush className="sidebar-menu-listgroup">
              {this.renderRootViewList()}
          </ListGroup>
      );
  }
  render() {
      return (
          <div>
              {this.renderLogo()}
              {this.renderMenu()}
              {this.renderSignout()}
          </div>
      )
  }
}

const preMapStateToProps = ({ firebase: { profile } }: { firebase: FirebaseReducer.Reducer }) => ({
  firebase: getFirebase(),
  profile,
});

export default compose<any>(
  withRouter,
  connect(preMapStateToProps, { ClearFirestoreData }),
)(Sidebar);
