import { ActionCreator } from 'redux';
import { functions } from '../config/firebase';

export const GenerateDocument: ActionCreator<any> = (
  id: string,
  assessment: string,
  type: string,
) => {
  return async () => {
    try {
      const generateDocument = functions.httpsCallable('GenerateDocument');
      const result = await generateDocument({ id, assessment, type });
      return result;
    } catch (ex) {
      console.error(ex);
    }
  };
};
