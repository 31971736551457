import React from 'react';
import { Icon } from 'react-icons-kit';
import { x } from 'react-icons-kit/feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button } from 'reactstrap';
import { QuoteSidePanelProps } from '../typings';
import Details from './Details';

const SidePanel:React.FC<QuoteSidePanelProps> = (props: QuoteSidePanelProps) => {

  const onClose = () => {
    props.onClose();
  }


  const renderDetails = () => {
    const { assessment } = props;
    if (!assessment) {
      return false;
    }
    return (
      <div className="part-details">
        <h1 className="text-right">
          {assessment.id}
        </h1>
        <PerfectScrollbar className="perfectscroll-part-details" options={{ suppressScrollX: true, wheelPropagation: false }}>
          <Details assessment={assessment} />
        </PerfectScrollbar>
      </div>
    )
  }

 
    
  return (
    <div className={`floating-controls supplier ${props.assessment ? 'open' : ''}`}>
      <Button color="link" className="floating-close-btn" onClick={onClose}>
        <Icon icon={x} size={24} />
      </Button>
      {renderDetails()}
    </div>
  )
}


export default SidePanel;