import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useChecklist } from 'react-checklist';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Table, Label, Input, FormGroup, Row, Col, Button } from 'reactstrap';
import { compose } from 'redux';
import { ConfirmParts, InitPartConfirmation, UpdatePartConfirmation } from '../../../../actions/WIP';
import { IPartConfirmationReducer } from '../../typings/Assessments';
import { IOrderPart, OrderDetailsProps } from '../typings';
import Icon from 'react-icons-kit';
import { fileText,x, check } from 'react-icons-kit/feather';
import { firestoreConnect, FirestoreReducer, ReduxFirestoreQueries } from 'react-redux-firebase';
import { GenerateDocument } from '../../../../actions/Files';
import { openPdf } from '../../../../helpers/utils';
import { LoaderSmall } from '../../../common/LoaderSmall/LoaderSmall';

const Details: React.FC<OrderDetailsProps> = (props: OrderDetailsProps) => {
  const [supplierParts, setSupplierParts] = useState<IOrderPart[]>([]);
  const [purchaseOrderLoading, setpurchaseOrderLoading] = useState<{ [key: string]: boolean }>({});
  const { handleCheck, checkedItems } = useChecklist(supplierParts, {
    key: 'id',
    keyType: 'string',
  });
  useEffect(() => {
    const payload: IOrderPart[] = [];
    _.each(props.parts, (part) => {
      payload.push(part);
    });
    setSupplierParts(payload);
    props.InitPartConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const partArray = Array.from(checkedItems);
    props.UpdatePartConfirmation('parts', partArray);
    // only want to update when checked items, change, not props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedItems]);
  
  const renderPart = (part:IOrderPart, index:number) => {
    const isDisabled = part.status?.invoiced || part.status?.delivered;
    return (
      <tr key={`part-${index}`} className={isDisabled ? 'disabled': ''}>
        <td className="text-center">
        <FormGroup check inline>
          <Label check>
            <Input
              type="checkbox"
              data-key={part.id}
              checked={checkedItems.has(part.id)}
              onChange={handleCheck}
              disabled={isDisabled}
            />
            <span />
          </Label>
          </FormGroup>
        </td>
        <td className={`text-left ${isDisabled ? 'text-muted': ''}`}>{part.description}</td>
        <td className={`text-center ${isDisabled ? 'text-muted': ''}`}>{part.standard}</td>
        <td className={`text-right ${isDisabled ? 'text-muted': ''}`}>R {part.part?.matchedPart?.costPrice ? part.part?.matchedPart?.costPrice.toFixed(2) : '-'}</td>
        <td className={`text-right ${isDisabled ? 'text-muted': ''}`}>R {part.price.toFixed(2)}</td>
        <td className={`text-center ${isDisabled ? 'text-muted': ''}`}>{part.orderNumber}</td>
        <td className="text-left">
          {isDisabled && <div className="part-confirmation"><Icon icon={check} size={24}/> Invoiced</div>}
        </td>
        <td className="text-center">
          {purchaseOrderLoading && purchaseOrderLoading[part.id] === true ? (
              <LoaderSmall additionalClass="part-button-loader" />
            ) : (
              <Button
                color="link"
                onClick={() => downloadPurchaseOrder(part.orderNumber, part.id)}>
                Purchase Order
              </Button>
            )}
        </td>
      </tr>
    );
  }

  const confirmDelivery = async () => {
    await props.ConfirmParts(props.assessment?.id, props.assessment?.insurer, props.partConfirmation);
    props.UpdatePartConfirmation('file', null, 'invoice', null, null);
    props.UpdatePartConfirmation('file', null, 'proofOfDelivery', null, null);
  };

  const updateFile = (file: File, type: string) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const name = file.name;
      const lastDot = name.lastIndexOf('.');
      const ext = name.substring(lastDot + 1);
      props.UpdatePartConfirmation('file', reader.result, type, name, ext);
    };
    reader.onerror = (error) => {
      const message =
        error instanceof Error
          ? error.message
          : _.isString(error)
          ? error
          : 'An unknown error has occurred';
      toast.error(message);
    };
  };

  const renderDropZone = (type: 'invoice' | 'proofOfDelivery', label: string) => {
    const {
      partConfirmation: { files },
    }: { partConfirmation: IPartConfirmationReducer } = props;
    if (files[type] && files[type].filename) {
      return (
        <div className="filename-preview">
          <div className="filename">
            <div className="icon">
              <Icon icon={fileText} size={72} />
            </div>
            <div className="text">
              {files[type].filename}
            </div>
          </div>
          <div className="actions">
            <Button
              className="file-x-button"
              onClick={() => props.UpdatePartConfirmation('file', null, type, null, null)}>
              <Icon icon={x} size={24} />
            </Button>
          </div>
        </div>
      );
    }
    return (
      <Dropzone onDrop={(acceptedFiles) => updateFile(acceptedFiles[0], type)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className="file-dropzone">
              <input {...getInputProps()} />
              <p>Drag and drop your {label} here, or click to select files</p>
            </div>
          </section>
        )}
      </Dropzone>
    );
  };

  const renderDropzones = () => {
    return (
      <Row>
          <Col>{_.size(checkedItems) > 0 ? renderDropZone('invoice', 'invoice') : false}</Col>
          <Col>
            {_.size(checkedItems) > 0
              ? renderDropZone('proofOfDelivery', 'proof of delivery')
              : false}
          </Col>
        </Row>
    );
  }

  const renderInvoiceTo = () => {
    if(!props.assessment) {
      return <React.Fragment />;
    }
    
    return (
      <Table borderless className="invoice-to-table">
        <colgroup>
          <col width="30%" />
          <col width="70%" />
          
        </colgroup>
        <thead>
        <tr>
          <th className="bold">
            <h3>Invoice To</h3>
          </th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td className="bold">Insurer Name</td>
            <td>{props.assessment?.insurer?.toUpperCase() || '-'}</td>
          </tr>
          <tr>
            <td className="bold">Insurer Account</td>
            <td>{props.insurer ? props.insurer?.directInvoicing?.debtorsAccount : '-'}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
  const { assessment, parts } = props;
  const getFirstPart = ():IOrderPart | null  => {
    const firstPartKey = _.chain(props.parts).map(p => p.id).first().value();
    let firstPart = null;
    if(firstPartKey && props.parts) {
      firstPart = props.parts[firstPartKey] || null;
    }
    return firstPart;
  }

  const renderDeliverTo = () => {
    
    const firstPart = getFirstPart();
    const details = firstPart ? firstPart.details.repairer : null;
    return (
      <Table borderless className="deliver-to-table">
        <colgroup>
          <col width="30%" />
          <col width="70%" />
          
        </colgroup>
        <thead>
        <tr>
          <th className="bold">
            <h3>Deliver To</h3>
          </th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td className="bold">Name</td>
            <td>{details?.name || '-'}</td>
          </tr>
          <tr>
            <td className="bold">Address</td>
            <td>{details?.address.addressLine1 || '-'}</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>{details?.address.addressLine2 || '-'}</td>
          </tr>
          <tr>
            <td className="bold">Ordered On</td>
            <td>{assessment?.audit.createdAt ? moment(assessment?.audit.createdAt.toDate()).format('YYYY-MM-DD') : '-'}</td>
          </tr>
          <tr>
            <td className="bold">Deliver On</td>
            <td>{firstPart ? firstPart.deliverBy : '-'}</td>
          </tr>
        </tbody>
      </Table>
    );
  }

  const downloadPurchaseOrder = async (id:string, partId: string) => {
    // id, assessment, type
    const assessment = props.assessment?.id;
    setpurchaseOrderLoading({ ...purchaseOrderLoading, [partId]: true });
    try {
      const response = await props.GenerateDocument(id, assessment, 'purchaseorder');
      setpurchaseOrderLoading({ ...purchaseOrderLoading, [partId]: false });
      openPdf(response.data?.data?.result);
      return true;
    } catch (ex) {
      console.error(ex);
      setpurchaseOrderLoading({ ...purchaseOrderLoading, [partId]: false });
      toast.error(`Could not fetch file. ${ex.message || 'An unknown error has occurred'}`);
      return false;
    }
  }

  if(!assessment || !parts) return <div></div>;
  return (
    <div className="part-information">
      <div className="order-info">
        <Row>
          <Col xs={6}>
            {renderInvoiceTo()}
          </Col>
          <Col xs={6}>
            {renderDeliverTo()}
          </Col>
        </Row>
      </div>
      <div className="order-parts">
        <Table striped className="">
          <colgroup>
            <col width="5%" />
            <col width="30%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="15%" />
          </colgroup>
          <thead>
            <tr>
              <th className="text-center">Select</th>
              <th>Description</th>
              <th className="text-center">Standard</th>
              <th className="text-right">Cost Price</th>
              <th className="text-right">Sale Price</th>
              <th className="text-center">Order Number</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {_.map(parts, renderPart)}
          </tbody>
        </Table>
        {renderDropzones()}
      </div>
      <div className="action-buttons">
        <Button color="primary" size="lg" onClick={confirmDelivery} disabled={!props.partConfirmation.ready}>
          {
            props.partConfirmation.loading
            ? 'Confirming...'
            : 'Confirm Delivery'
          }
        </Button>
      </div>
    </div>
  );
};

const mapQueryToProps = (props:any) => {
  if(!props.assessment) {
    return [];
  }
  const queries: ReduxFirestoreQueries = [{
    collection: 'parts_v2',
    where: ['assessment', '==', props.assessment.id],
    storeAs: 'parts',
  },{
    collectionGroup: 'orders',
    where: [
      ['assessment', '==', props.assessment.id],
      ['status.ordered', '==', true],
    ],
    storeAs: 'orders',
  }, {
    collection: 'underwriters',
    doc: props.assessment.insurer.toUpperCase(),
    storeAs: 'insurer',
  }]
  return queries;
}

const mapStateToProps = ({ partConfirmation, firestore }: { partConfirmation: IPartConfirmationReducer, firestore: FirestoreReducer.Reducer }) => {
  const assessmentParts = firestore.data.parts || null;
  const assessmentOrders = firestore.data.orders || null;
  const insurer = firestore.data.insurer || null;
  const parts = _.chain(assessmentOrders).map(order => {
    const part = assessmentParts[order.id];
    if(part) {
      return {...order, part };
    }
    return null;
  }).compact().keyBy('id').value();
  return { partConfirmation, parts, insurer };
};
export default compose<React.ComponentType<Partial<OrderDetailsProps>>>(
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, {UpdatePartConfirmation, InitPartConfirmation, ConfirmParts, GenerateDocument })
)(Details);