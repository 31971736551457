import { Button, Table } from 'reactstrap';
import {
  FirebaseReducer,
  FirestoreReducer,
  firestoreConnect,
  ReduxFirestoreQueries,
} from 'react-redux-firebase';
import { IQuoteReducer } from '../typings/Assessments';
import { LoadMore, ResetPaging } from '../../../actions/Pagination';
import { LoadingOverlayExtended } from '../../common/LoadingOverlay/LoadingOverlayExtended';
import { SelectAssessment } from '../../../actions/Assessment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../style/Assessments.scss';
import { ISearchReducer } from '../../../typings';
import history from '../../../helpers/history';
import SidePanel from './components/SidePanel';

import { IAssessmentV2 } from '../../Assessments//typings/Assessments';
import { EmptyState } from '../../common/EmptyState/EmptyState';

const QueueWrapper: React.FC<any> = (props: any) => {
  const [selectedAssessment, setSelectedAssessment] = useState<IAssessmentV2 | null>(null);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [perfectScrollRef, setPerfectScrollRef] = useState<HTMLElement>();
  useEffect(() => {
    if (
      props.urlId &&
      props.urlId !== props.selectedAssessment?.id &&
      !_.isEmpty(props.assessments)
    ) {
      props.SelectAssessment({...props.assessments[String(props.urlId)], id: props.urlId});
    }
  }, [props, props.urlId]);

  useEffect(() => {
    const assessmentIds = _.keys(props.assessments);
    if (
      props.urlId &&
      props.selectedAssessment &&
      !props.loading &&
      !_.includes(assessmentIds, props.selectedAssessment.id)
    ) {
      props.SelectAssessment(null);
      history.push('/tasks/quoting');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.assessments, props.urlId, props.loading, props.selectedAssessment]);


  const startScrolling = () => {
    
    if(perfectScrollRef && perfectScrollRef.scrollTop > 100 && !isScrolling) {
      setIsScrolling(true);
    } else if(perfectScrollRef && perfectScrollRef.scrollTop <= 100 && isScrolling) {
      setIsScrolling(true);
    }
  }

  const renderTableHeadings = () => {
    const scrollClass = isScrolling ? 'scrolling' : '';
    const activeClass = false // this.state.headerActive ? 'active' : '';
    return (
        <thead>
          <tr className={`${scrollClass} ${activeClass}`} >
            <th className="controlled-header">
              <div className="title">Assessment Number</div>
              <div className="control empty"></div>
            </th>
            <th className="text-center controlled-header">
              <div className="title">Make</div>
              <div className="control empty"></div>
            </th>
            <th className="text-center controlled-header">
              <div className="title">Model</div>
              <div className="control empty"></div>
            </th>
            <th className="text-center controlled-header">
              <div className="title">Work Provider</div>
              <div className="control empty"></div>
            </th>
            <th>&nbsp;</th>
          </tr>
        </thead>
    );
  }

  
  const selectAssessment = (assessment: IAssessmentV2 | null) => {
    setSelectedAssessment(assessment);
  }

  const renderTableRow = (assessment: IAssessmentV2, id: string) => {
    if(!assessment) {
      return false;
    }
    const { selectedAssessment } = props;
    const selectedAssessmentId = selectedAssessment ? selectedAssessment.id === id : false;
    return (
      <tr key={id} className={selectedAssessmentId ? 'assessment-row active' : 'assessment-row'}>
        
        <td className="text-left">
          <Button
            className="list-action-btn"
            onClick={() => selectAssessment(assessment)}
          >
            {assessment.id}
          </Button>
        </td>
        <td className="text-center">
          {assessment.manufacturer}
        </td>
        <td className="text-center">
          {assessment.model}
        </td>
        <td className="text-center">
          {assessment.insurer}
        </td>
        <td className="td-action">
          &nbsp;
        </td>
      </tr>
    );
  }

  const renderTableBody = () => {
    if(props.loadingAssessments) {
      return false;
    }
    return (
        <tbody>
          {_.map(props.assessments, renderTableRow)}
        </tbody>
    );
  }

  const renderTable = () => {
    return (
      <div className="fill-container">
      <Table className="spaced-table hoverable">
        <colgroup>
          <col width="20%" />
          <col width="15%" />
          <col width="20%" />
          <col width="20%" />
          <col width="20%" />
          <col width="5%" />
        </colgroup>
        {renderTableHeadings()}
        {renderTableBody()}
      </Table>
      </div>
    );
  }

  const renderTableOrEmptyState =() => {
    if(!_.isEmpty(props.assessments)) {
      return renderTable();
    }
    return <EmptyState />
  }

  return (
    <LoadingOverlayExtended loading={props.loading}>
      <PerfectScrollbar
        className="main-perfectscroll" 
        containerRef={ (ref) => setPerfectScrollRef(ref) }
        onScrollY={startScrolling}
      >
        <div className={`container filled-container ${selectedAssessment ? 'open' : ''}`}>
          <div className="container-overlay"></div>
          {renderTableOrEmptyState()}
          <SidePanel assessment={selectedAssessment} onClose={() => selectAssessment(null)} />
        </div>
      </PerfectScrollbar>
    </LoadingOverlayExtended>
  );
};

const mapQueryToProps = () => {
  const queries:ReduxFirestoreQueries = [{
    collection: 'assessments_v2',
    where: [
      ['active', '==', true],
      ['status.quoting', '==', true],
      ['status.submittedForQuote', '==', false]
    ],
    limit: 50,
    storeAs: 'assessments',
  }]
  return queries;
}

const mapStateToProps = (state: any, ownProps: any) => {
  const {
    firestore,
    firebase: { auth, profile },
    pagination,
    assessment,
    quote,
    search,
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
    pagination: { skip: number; take: number };
    assessment: { selectedAssessment: IAssessmentV2 };
    quote: IQuoteReducer;
    search: ISearchReducer;
  } = state;
  let assessmentsObj: { [key: string]: IAssessmentV2 } = firestore.data.assessments;
  const urlId: string | null = ownProps.match.params.assessmentId
    ? ownProps.match.params.assessmentId
    : null;
  const path = ownProps.path;
  // Load up search results
  if (!_.isEmpty(search.results) && !search.error) {
    const searchResultsArray: { [key: string]: IAssessmentV2 } = _.chain(firestore.data)
      .filter((v, k) => {
        return _.startsWith(k, 'assessment_search') && !_.isNil(v);
      })
      .compact()
      .keyBy('assessment')
      .value();
    assessmentsObj = {};
    _.each(search.results, (result) => {
      const searchAssessment = searchResultsArray[result.assessment.AssessmentNumber];
      if (searchAssessment) assessmentsObj[result.id] = searchAssessment;
    });
  }
  const loading = firestore.status.requesting.assessments || search.searching;
  
  return {
    urlId,
    path,
    auth,
    profile,
    quote,
    assessments: assessmentsObj || {},
    loading,
    pagination,
    selectedAssessment: assessment.selectedAssessment,
  };
};

export default compose<React.ComponentType<any>>(
  withRouter,
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, {
    LoadMore,
    ResetPaging,
    SelectAssessment,
  }),
)(QueueWrapper);
