export const DECORATE_TOKEN = 'DECORATE_TOKEN';

export const FETCH_SUPPLIER = 'FETCH_SUPPLIER';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';

export const FETCH_REPAIRER = 'FETCH_REPAIRER';
export const UPDATE_REPAIRER = 'UPDATE_REPAIRER';

export const FETCH_PART_STANDARDS = 'FETCH_PART_STANDARDS';

export const SUBMIT_QUOTE = 'SUBMIT_QUOTE';
export const SUBMIT_QUOTE_IN_PROGRESS = 'SUBMIT_QUOTE_IN_PROGRESS';
export const SUBMIT_QUOTE_FAIL = 'SUBMIT_QUOTE_FAIL';

export const ADD_IMAGE = 'ADD_IMAGE';
export const RESET_IMAGES = 'RESET_IMAGES';
export const SELECT_ASSESSMENT = 'SELECT_ASSESSMENT';

export const UPDATE_INSURER = 'UPDATE_INSURER';

export const BASKET_ADD_PART = 'BASKET_ADD_PART';
export const BASKET_REMOVE_PART = 'BASKET_REMOVE_PART';
export const BASKET_UPDATE_ORDERNO = 'BASKET_UPDATE_ORDERNO';
export const START_ORDER = 'START_ORDER';
export const PLACE_ORDER = 'PLACE_ORDER';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ORDER_FAIL = 'ORDER_FAIL';

export const CONFIRM_PARTS_INIT = 'CONFIRM_PARTS_INIT';
export const CONFIRM_PARTS_UPDATE_PART = 'CONFIRM_PARTS_UPDATE_PART';
export const CONFIRM_PARTS_UPDATE_FILE = 'CONFIRM_PARTS_UPDATE_FILE';
export const CONFIRM_PARTS_IN_PROGRESS = 'CONFIRM_PARTS_IN_PROGRESS';
export const CONFIRM_PARTS_SUCCESS = 'CONFIRM_PARTS_SUCCESS';
export const CONFIRM_PARTS_FAIL = 'CONFIRM_PARTS_FAIL';

export const QUOTE_PARTS_INIT = 'APPROVE_PARTS_INIT';
export const QUOTE_PARTS_UPDATE = 'APPROVE_PARTS_UPDATE';

export const SEARCH_ASSESSMENTS_IN_PROGRESS = 'SEARCH_ASSESSMENTS_IN_PROGRESS';
export const SEARCH_ASSESSMENTS_SUCCESS = 'SEARCH_ASSESSMENTS_SUCCESS';
export const SEARCH_ASSESSMENTS_FAIL = 'SEARCH_ASSESSMENTS_FAIL';
